@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
body {
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    /* overflow: hidden; */
}

button:focus {
    outline: none !important;
}

.bg-logo {
    background-image: url("./assets//images/logo.png");
}

.unselected-element {
    cursor: default;
    user-select: none !important;
    outline: dashed 1px #1976d2;
}

.unselected-element:hover {
    outline: solid 1px #1976d2;
}

.selection-element:hover {
    outline: solid 2px #e57373;
}

.selected-element {
    outline: solid 1px #1565c0;
}

.panel-container {
    border-radius: 4px;
    position: relative;
    margin-bottom: 4px;
}

.text-input-sm input {
    padding: 7px 10px;
    font-size: 0.85em;
}

.text-input-sm .MuiSelect-root {
    padding: 6px 8px;
    font-size: 0.85em;
}

.text-input-sm {
    width: 100%;
}

.adorned-text-input-sm input {
    padding-top: 7px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 2px;
    font-size: 0.85em;
}

.adorned-text-input-sm label {
    font-size: 0.9em;
}

.panel-label {
    position: absolute !important;
    font-size: 11px !important;
    top: -8px !important;
    left: 5px !important;
    background-color: #FFF !important;
}

.sketch-picker {
    box-shadow: none !important;
}

.autocomplete-paper {
    max-height: 200px;
}

.file-icon {
    width: 100%;
    height: 100%;
    max-height: 85px;
    min-height: 45px;
    min-width: 45px;
    background-origin: content-box;
    padding: var(--spacing1);
    background-position: center;
    max-width: 65px;
}

.file-text {
    font-size: 80%;
    word-break: break-all;
    height: 100%;
}

.file-dark-contrast-text {
    background-color: rgba(255, 255, 255, 0.15);
}

.file-word-icon {
    background-image: url("./assets/images/word.svg");
}

.file-excel-icon {
    background-image: url("./assets/images/excel.svg");
}

.file-power-point-icon {
    background-image: url("./assets/images/powerpoint.svg");
}

.file-txt-icon {
    background-image: url("./assets/images/txt.svg");
}

.file-pdf-icon {
    background-image: url("./assets/images/pdf.svg");
}

.file-xml-icon {
    background-image: url("./assets/images/xml.svg");
}

.file-zip-icon {
    background-image: url("./assets/images/zip.svg");
}

.folder-icon {
    background-image: url("./assets/images/folder.png");
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.list-text-sm {
    font-size: 0.65em !important;
}

.curtain-appear-view {
    opacity: 0 !important;
    transition: 0.5s opacity;
}

.curtain-appear-view:hover {
    opacity: 1 !important;
}

.curtain-disappear-view {
    opacity: 1 !important;
    transition: 0.5s opacity;
}

.curtain-disappear-view:hover {
    opacity: 0 !important;
}

.float-unselected-element:hover {
    outline: solid 3px #ffca28;
}

.draggable-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.attributes-dragable {
    cursor: move;
}

.all-pointer-events {
    pointer-events: all;
}

.text-color-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: white;
}

.selection-menu {
    position: fixed;
    right: calc(50vw - 60px);
    top: 8px;
    background-color: rgba(144, 202, 249, 0.75);
}

.internal-link {
    color: var(--primaryMainColor) !important;
    transition: 0.5s;
    text-decoration: underline;
    text-decoration-color: var(--primaryMainColor);
    text-decoration-style: double !important;
}

.entity-link {
    color: var(--entitySearchMainColor) !important;
    transition: 0.5s;
    transition: 0.5s;
    text-decoration: underline;
    text-decoration-color: var(--warningMainColor);
    text-decoration-style: dotted !important;
}

.internal-link:active {
    color: white !important;
    transition: 0.5s;
    background-color: var(--primaryMainColor);
}

.entity-link:active {
    color: white !important;
    transition: 0.5s;
    background-color: var(--warningMainColor);
}

.internal-link:visited {
    color: inherit !important;
    text-decoration: underline;
    text-decoration-color: #3f51b5;
}

.entity-link:visited {
    color: var(--entitySearchLightColor) !important;
    text-decoration-style: dotted !important;
    text-decoration-color: var(--entitySearchLightColor)!important;
}

.internal-link:hover {
    color: var(--primaryMainColor) !important;
}

.entity-link:hover {
    color: var(--warningMainColor) !important;
}

.internal-link:visited span :hover {
    color: #3f51b5 !important;
}

.entity-link:visited span :hover {
    color: var(--entitySearchLightColor)!important;
}

.internal-link span :hover {
    color: var(--primaryMainColor) !important;
}

.entity-link span :hover {
    color: var(--warningMainColor) !important;
}

.link-no-hover:hover {
    background-color: initial !important;
    color: var(--primaryMainColor) !important;
}

.entity-link-no-hover:hover {
    background-color: initial !important;
    color: var(--warningMainColor) !important;
}

.MuiSpeedDialAction-staticTooltipLabel {
    font-size: 0.9em !important;
}

.MuiSpeedDialAction-fab {
    margin: 4px !important;
}

.MuiSpeedDialAction-staticTooltipLabel {
    width: 170px;
    text-align: center;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    line-height: 100%;
}

.folder-content-row {
    width: 140px;
    height: 214px;
    margin: 5px;
    border: solid 1px #f2f2f2;
    transition: 0.5s transform;
    cursor: pointer;
    background: white;
}

.folder-content-row:hover {
    transform: scale(1.05);
    box-shadow: -1px -2px 16px -4px rgba(0, 0, 0, 0.68);
    -webkit-box-shadow: -1px -2px 16px -4px rgba(0, 0, 0, 0.68);
    -moz-box-shadow: -1px -2px 16px -4px rgba(0, 0, 0, 0.68);
}

.folder-content-col {
    width: 100%;
    height: 80px;
    margin: 5px;
    border: solid 1px #f2f2f2;
    transition: 0.5s transform;
    cursor: pointer;
}

.folder-content-col:hover {
    transform: scale(1.025);
    box-shadow: -1px -2px 16px -4px rgba(0, 0, 0, 0.68);
    -webkit-box-shadow: -1px -2px 16px -4px rgba(0, 0, 0, 0.68);
    -moz-box-shadow: -1px -2px 16px -4px rgba(0, 0, 0, 0.68);
}

.hover-underline:hover {
    text-decoration: underline;
}

.folder-content:hover .folder-content-hover {
    display: flex;
}

.folder-content-hover {
    display: none;
    background-color: rgba(0, 0, 0, 0.4);
}

.white-icon-hover-primary {
    color: white;
    transition: 0.35s color;
}

a {
    margin: 0px !important;
    padding: 0px !important;
}

.white-icon-hover-primary:hover {
    color: var(--primaryMainColor);
}

.folder-content-img-row {
    width: 70%;
    bottom: 30%;
}

.folder-content-img-col {
    padding: 20px;
}

.fixed-element {
    position: fixed !important;
}

.save-position-actions {
    position: fixed;
    left: calc(50vw - 80px);
    top: 0;
    background-color: var(--primaryLightColor);
    border-radius: 4px;
}

.PrivateSwitchBase-root-9 {
    padding: 4px !important;
}


/**
*Se añade este estilo especialmente para tarjetas de perxona 
*/

.descriptionPerxona::-webkit-scrollbar {
    display: none;
}

.conferenceContainer::-webkit-scrollbar-thumb:hover {
    background: #4b4b4b;
}


.conferenceContainer ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}


/* Track */

.conferenceContainer ::-webkit-scrollbar-track {
    box-shadow: #000;
    border-radius: 10px;
}


/* Handle */

.conferenceContainer ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
}
